import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const WithParsing = Component => {
	const Enhanced = props => {
		const images = useStaticQuery(graphql`
			query {
				allFile(filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "parsing" } }) {
					edges {
						node {
							name
							childImageSharp {
								fluid(maxWidth: 930) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		`)
		return <Component {...props} images={images} />
	}

	return Enhanced
}

export default WithParsing

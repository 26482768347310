import React from 'react'

const PseudoLink = ({ to, onClick, children, ...rest }) => {
	const handleOpen = () => {
		if (onClick) onClick()
		else if (to) window.open(to, '_blank')
	}

	return (
		<span role="button" tabIndex={0} onClick={handleOpen} onKeyDown={() => {}} {...rest}>
			{children}
		</span>
	)
}

export default PseudoLink

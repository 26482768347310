import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Example.module.scss'
import loadable from '@loadable/component'
import PseudoLink from 'components/PseudoLink'
import memoize from 'utils/memoize'
import Link from 'components/LinkDuplicate'

const b = bem.example(css)

const Example = props => {
	const { icon, title, subtitle, description, type, url, target, onClick, isClickable, isVerticalCenter, tag } = props

	// with memoize
	const Svg = loadable(() => import(`./img/${icon}.svg`))
	if (!Svg) return null

	let Tag
	let attrs = {}
	switch (tag) {
		case 'pseudo-link':
			Tag = PseudoLink
			attrs = { to: url, title, target, onClick, rel: 'nofollow noopener noreferrer' }
			break

		case 'gatsby-link':
			Tag = Link
			attrs = { to: url, title, target, onClick, rel: 'nofollow noopener noreferrer' }
			break

		case 'a':
			Tag = 'a'
			attrs = { href: url, title, target, onClick, rel: 'nofollow noopener noreferrer' }
			break

		default:
			Tag = tag
	}

	return (
		<Tag {...attrs} className={b({ type, 'is-clickable': isClickable, 'is-vertical-center': isVerticalCenter })}>
			<div className={b('head')}>
				<div className={b('icon')}>
					<Svg />
				</div>
				<div className={b('title')} dangerouslySetInnerHTML={{ __html: title }} />
				{subtitle && <div className={b('subtitle')} dangerouslySetInnerHTML={{ __html: subtitle }} />}
			</div>
			<div className={b('description')}>
				<span dangerouslySetInnerHTML={{ __html: description }} />
			</div>
		</Tag>
	)
}

Example.defaultProps = {
	isClickable: true,
	type: 'block',
	tag: 'gatsby-link',
}

Example.propTypes = {
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
	url: PropTypes.string,
	type: PropTypes.oneOf(['block', 'inline']),
	onClick: PropTypes.func,
	isClickable: PropTypes.bool,
	isVerticalCenter: PropTypes.bool,
	tag: PropTypes.oneOf(['div', 'a', 'pseudo-link', 'gatsby-link']),
}

export default memoize(Example)

import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Feature.module.scss'
import Icon from 'components/Icon'
import Secondary from 'components/Secondary'

const b = bem.feature(css)

const Feature = ({ icon, title, description, negative }) => {
	return (
		<div className={b({ negative })}>
			<div className={b('icon')}>
				<Icon type={icon} />
			</div>
			<div className={b('title', 'h4')} dangerouslySetInnerHTML={{ __html: title }} />
			<Secondary className={b('description')} negative={negative}>
				<span dangerouslySetInnerHTML={{ __html: description }} />
			</Secondary>
		</div>
	)
}

Feature.propTypes = {
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	negative: PropTypes.bool,
}

export default Feature

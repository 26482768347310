import React from 'react'
import PropTypes from 'prop-types'

const Webp = props => {
	const { className, webp, png, jpg, alt, width, height } = props
	return (
		<picture className={className}>
			<source type="image/webp" srcSet={webp} />
			{png && <source type="image/png" srcSet={png} />}
			{jpg && <source type="image/jpeg" srcSet={jpg} />}
			<img src={png} alt={alt} width={width} height={height} />
		</picture>
	)
}

Webp.propTypes = {
	className: PropTypes.string,
	webp: PropTypes.string.isRequired,
	png: PropTypes.string,
	jpg: PropTypes.string,
	alt: PropTypes.string.isRequired,
}

export default Webp

import React from 'react'
import bem from 'bem'
import css from './Contacts.module.scss'
import Secondary from 'components/Secondary'
import TextIcon from 'components/TextIcon'
import PropTypes from 'prop-types'


const b = bem.contacts(css)

const Contacts = ({ color }) => (
	<div className={b({ color })}>
		<h2>Связаться с нами</h2>
		<Secondary className="mb-lg">Ответим на все ваши вопросы</Secondary>
		<ul className={b('items')}>
			<li>
				<TextIcon
					icon="envelopeEmpty"
					iconSize={22}
					iconColor="#8C8C8C"
					to="mailto:info@parsic.ru"
					title="Написать на электронную почту"
				>
					<strong>info@parsic.ru</strong>
				</TextIcon>
			</li>
			<li>
				<TextIcon
					icon="phoneEmpty"
					iconSize={22}
					iconColor="#8C8C8C"
					to="tel:+79997002201"
					title="Позвонить"
					className="text-nowrap"
				>
					<strong>+7 (999) 700-22-01</strong>
				</TextIcon>
			</li>
		</ul>
		<ul className={b('items')}>
			<li>
				<TextIcon
					icon="telegram"
					iconColor="#039BE5"
					textColor="#039BE5"
					to="https://telegram.me/iparsic"
					title="Написать в Telegram"
					target="_blank"
				>
					<strong>Telegram</strong>
				</TextIcon>
			</li>
			<li>
				<TextIcon
					icon="whatsapp"
					iconColor="#4CAF50"
					textColor="#4CAF50"
					to="https://api.whatsapp.com/send?phone=79997002201"
					title="Написать в WhatsApp"
					target="_blank"
				>
					<strong>WhatsApp</strong>
				</TextIcon>
			</li>
			<li>
				<TextIcon
					icon="vk"
					iconColor="#4A76A8"
					textColor="#4A76A8"
					to="https://vk.com/parsic_ru"
					title="Написать в ВКонтакте"
					target="_blank"
				>
					<strong>ВКонтакте</strong>
				</TextIcon>
			</li>
		</ul>
	</div>
)

Contacts.propTypes = {
	color: PropTypes.oneOf(['gray']),
}

export default Contacts

import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Features.module.scss'
import Feature from './components/Feature'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Sector, { sectorColors } from 'components/Sector'

const b = bem.features(css)

const Features = ({ title, data, negative, color, columns, isSector }) => {
	const rules = {
		3: {
			// container: { lg: 10, lgOffset: 1 },
			container: {},
			items: { xs: 12, sm: 6, md: 4 },
		},
		4: {
			container: {},
			items: { xs: 12, sm: 6, md: 3 },
		},
	}
	const rule = rules[columns]

	const Parent = ({ children }) =>
		isSector ? (
			<Sector title={title} color={color} titleCentered>
				{children}
			</Sector>
		) : (
			children
		)

	return (
		<Parent>
			<Grid fluid>
				<Row>
					<Col {...rule.container}>
						<Row center="xs" className={b()}>
							{data.map(({ icon, title, description }, index) => (
								<Col key={`feature-${index}`} {...rule.items}>
									<Feature icon={icon} title={title} description={description} negative={negative} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Grid>
		</Parent>
	)
}

Features.defaultProps = {
	color: 'white',
	columns: 3,
	isSector: true,
}

Features.propTypes = {
	columns: PropTypes.oneOf([3, 4]),
	title: PropTypes.string,
	negative: PropTypes.bool,
	isSector: PropTypes.bool,
	color: PropTypes.oneOf(sectorColors).isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
}

export default Features

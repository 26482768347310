import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Banner from 'components/Banner'
import Features from 'components/Features'
import Examples from 'components/Examples'
import Contacts from 'components/Contacts'
import FeedbackButton from 'components/FeedbackButton'
import FAQ from 'components/FAQ'
import Img from 'gatsby-image'

const ParsingShop = ({ images }) => {
	const pictures = {}
	images.allFile.edges.forEach(({ node }) => {
		pictures[node.name] = node.childImageSharp.fluid
	})
	return (
		<Layout>
			<SEO
				title="Парсинг товаров интернет-магазинов — «Парсик»"
				description="Закажите парсинг товаров с интернет-магазинов. Получите Excel файл с товарами, ценами и загрузите их на свой сайт"
				keywords="парсинг, заказ, база, excel, интернет-магазин, цены, товары, телефоны, email"
			/>
			<Banner
				title="Парсинг товаров"
				description="Всегда свежие товары в&nbsp;вашем интернет-магазине"
				image={{ name: 'home', width: 506, height: 392 }}
			>
				<FeedbackButton theme="light" size="lg">
					Заказать парсинг
				</FeedbackButton>
			</Banner>
			<Sector>
				<Grid fluid className="mb-xl">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<h2 className="mb">Тысячи товаров для&nbsp;интернет-магазина</h2>
							<p className="fs-h4">
								Обновляйте товары, цены, акции товаров. Анализируйте сайты ваших конкурентов и&nbsp;будьте всегда
								на&nbsp;шаг впереди. Это действительно просто
							</p>
						</Col>
					</Row>
				</Grid>
				<div className="mb-xl">
					<Features
						columns={3}
						isSector={false}
						data={[
							{
								url: '/',
								icon: 'businessman',
								title: 'Сосредоточьтесь на&nbsp;бизнесе',
								description: 'Направьте свои силы на&nbsp;продажи, а&nbsp;не&nbsp;на&nbsp;наполнение интернет-магазина',
							},
							{
								url: '/',
								icon: 'folders',
								title: 'Расширьте ассортимент',
								description: 'Спарсим и&nbsp;предоставим вашему сайту десятки тысяч товаров в&nbsp;Excel файле',
							},
							{
								url: '/',
								icon: 'analyze',
								title: 'Анализируйте конкурентов',
								description: 'Будьте в&nbsp;курсе новинок, акций и&nbsp;создайте лучшее предложение вашим клиентам',
							},
						]}
					/>
				</div>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Узнать стоимость
					</FeedbackButton>
				</div>
			</Sector>
			<Sector color="blue" title="Закажите парсинг товаров для интернет-магазина" isGradient titleCentered>
				<div className="fs-h4 text-center">
					<p>
						Ежедневно мы&nbsp;будем присылать вам свежие данные товаров в&nbsp;Excel файле. Вы&nbsp;сможете быстро
						и&nbsp;просто обновлять их&nbsp;в&nbsp;своем интернет-магазине. Ваши клиенты всегда будут видеть только
						актуальную информацию
					</p>
				</div>
			</Sector>
			<Sector>
				<Grid fluid className="mb-lg">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<h2 className="mb">Пример данных для парсинга</h2>
							<p className="fs-h4">
								Любая информация на&nbsp;ваш выбор, например:
								<br />
								aртикул, категория, название, цены, производитель, в наличии, метка, описание, характеристики,
								URL-адрес, изображения, магазины
							</p>
						</Col>
					</Row>
				</Grid>
				<div className="mb-xl">
					<Img fluid={pictures['market-screen']} alt="Парсинг товара со страницы интернет-магазина" />
				</div>
				<Grid fluid className="mb-lg">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<h2 className="mb">Пример результата парсинга</h2>
							<p className="fs-h4">
								Хорошо сформированный Excel файл с&nbsp;товарами интернет-магазина для последующего импорта в&nbsp;ваш
								сайт
							</p>
						</Col>
					</Row>
				</Grid>
				<Img fluid={pictures['market-excel']} alt="Excel файл с данными парсинга интернет-магазина" />
			</Sector>
			<Examples />
			<Sector title="Вопросы и ответы">
				<div className="mb-lg">
					<FAQ
						isNumbers={true}
						items={[
							{
								title: 'Что такое парсинг товаров интернет-магазинов?',
								description: (
									<>
										<p>
											<strong>Парсинг товаров</strong>&nbsp;&mdash; это автоматические сканирование интернет-магазинов
											для получения информации о&nbsp;товарах, ценах, акциях, категориях и&nbsp;прочей сопутствующей
											информации с&nbsp;целью дальнейшего обновления ассортимента каталога товаров на&nbsp;вашем сайте.
										</p>
										<p>
											Полученные данные аккуратно складываются в&nbsp;Excel файлик, который впоследствии удобно
											используется для анализа данных и&nbsp;импорта на&nbsp;свой сайт.
										</p>
									</>
								),
							},
							{
								title: 'Сколько стоит парсинг товаров?',
								description: (
									<>
										<p>
											Стоимость парсинга стандартного интернет-магазина с&nbsp;идентичной структурой разделов каталога
											стоит в&nbsp;среднем 5000&nbsp;руб. Однако, если категории интернет-магазина имеют разную
											структуру, тогда цена будет выше, но, как правило, цена отличается не&nbsp;сильно. Это связано
											с&nbsp;тем, что под каждый раздел сайта, фактически, необходимо писать отдельный парсер.
											И&nbsp;в&nbsp;будущем необходимо поддерживать несколько парсеров вместо одного.
										</p>
										<p>
											Для уточнения цены, напишите нам через форму обратной связи, какой интернет-магазин и&nbsp;какую
											информацию вы&nbsp;хотите парсить. Мы&nbsp;скажем вам точную цену.
										</p>
									</>
								),
							},
							{
								title: 'Какие данные можно парсить с&nbsp;интернет-магазинов?',
								description: (
									<>
										<p className="no-last">
											Вы&nbsp;сможете получить с&nbsp;сайтов любую информацию о&nbsp;товарах, а&nbsp;также связанную
											с&nbsp;ней, например:
										</p>
										<ol>
											<li>Названия товаров и&nbsp;артикулы</li>
											<li>Категория товара, бренд</li>
											<li>Обычная и&nbsp;скидочная цена товара</li>
											<li>Краткое и&nbsp;полное описание товара с&nbsp;функциями, характеристиками</li>
											<li>Изображения, видео, прикрепленные файлы (инструкции, руководства)</li>
											<li>Комментарии, оценки, рекомендации о&nbsp;товаре</li>
											<li>Информация о&nbsp;количестве покупок товара, наличии, доставке, рейтинге, акциях</li>
										</ol>
									</>
								),
							},
							{
								title: 'Зачем нужен парсинг товаров?',
								description: (
									<>
										<p className="no-last">Преимущества парсинга товаров невозможно переоценить:</p>
										<ol>
											<li>
												Парсинг сайтов особождает от&nbsp;ручной работы наполнения каталога интернет-магазина. Это
												особенно актуально, когда товаров несколько тысяч. Имея хорошо структурированный Excel файл
												с&nbsp;информацией о&nbsp;товарах, импорт даже с&nbsp;автоматической загрузкой изображений
												на&nbsp;сайт происходит за&nbsp;считанные минуты
											</li>
											<li>
												Парсинг интернет-магазинов позволяет следить за&nbsp;обновлениями на&nbsp;сайте доноре
												и&nbsp;своевременно обновлять ее&nbsp;на&nbsp;вашем сайте. Ведь товар имеет свойство
												заканчиваться, а&nbsp;также может меняться цена товара или поставщик может выпустить новый,
												&laquo;вирусный&raquo; товар, который будет пользоваться бешеным спросом здесь и&nbsp;сейчас.
												Таким образом клиентам на&nbsp;вашем сайте будет доступна только актуальная информация
											</li>
											<li>
												Ни&nbsp;для кого не&nbsp;секрет, что люди ищут товары дешевле и&nbsp;выгоднее, даже если цена
												товара отличается всего на&nbsp;пару процентов. Парсинг позволяет устанавливать цены чуть ниже,
												чем у&nbsp;конкурентов. Поисковые системы и&nbsp;маркетплейсы, как правило, выводят
												интернет-магазины с&nbsp;самой низкой ценой на&nbsp;первое место, акцентируют на&nbsp;них
												внимание посетителей. Это значит, что такой интернет-магазин будет иметь существенное
												преимущество по&nbsp;привлечению клиентов&nbsp;и, как следствие, такой сайт будет иметь больше
												продаж
											</li>
										</ol>
									</>
								),
							},
							{
								title: 'Кому подходит парсинг интернет-магазинов?',
								description: (
									<>
										<p>
											Парсинг подходит как мелким предпринимателям, партнерам, занимающимся перепродажей товаров
											на&nbsp;своих сайтах, так и&nbsp;крупным, известным компаниям, вынужденным следить
											за&nbsp;активностью конкурентов и&nbsp;своевременно менять предложение для своих клиентов.
										</p>
										<p>
											Например, интернет-магазин может парсить китайские товары с&nbsp;зарубежных сайтов, например
											с&nbsp;AliExpress и&nbsp;реализовывать их&nbsp;по&nbsp;более высоким ценам в&nbsp;регионах. Или
											М-Видео предлагает акцию на&nbsp;своем сайте &laquo;найди дешевле и&nbsp;мы&nbsp;снизим
											цену&raquo;. Это отличное маркетинговое решение, которые привлекает клиентов, но, по&nbsp;факту,
											товара дешевле не&nbsp;существует нигде, т.к. информация о&nbsp;ценах обновляется
											за&nbsp;считанные минуты сразу на&nbsp;всех подобных сайтах.
										</p>
									</>
								),
							},
							{
								title: 'Как использовать полученные данные товаров?',
								description: (
									<>
										<p>
											В&nbsp;вашем распоряжении будет доступен Excel файл с&nbsp;актуальной информацией о&nbsp;товарах.
											Это универсальный формат, который можно легко использовать для импорта в&nbsp;ваш
											интернет-магазин. При импорте автоматически загружаются фотографии товаров, обновляются тексты,
											цены, создаются новые и&nbsp;удаляются старые товары. Это делается в&nbsp;несколько кликов.
										</p>
										<p>
											Если CMS вашего интернет-магазина не&nbsp;поддерживает импорт, то&nbsp;использовать Excel файлик
											поможет собственное программное решение. На&nbsp;сегодняшний день существует множество способов
											прочитать и&nbsp;использовать данные из&nbsp;Excel файла на&nbsp;своем сайте в&nbsp;разных языках
											программирования.
										</p>
									</>
								),
							},
							{
								title: 'Сколько времени нужно на&nbsp;разработку парсера товаров?',
								description: (
									<>
										<p>
											Написание парсера товаров интернет-магазина занимает около трех дней. Но, как правило, итоговый
											результат клиент получает в&nbsp;течение недели, так как в&nbsp;процессе работы дополнительно
											обсуждаются детали, вносятся корректировки, утверждается работа.
										</p>
										<p>
											Если у&nbsp;нас возникают трудности парсинга, например, сайт донор имеет защиту, то&nbsp;время
											незначительно увеличивается. Об&nbsp;этом мы&nbsp;предупреждаем клиента заблаговременно.
										</p>
									</>
								),
							},
							{
								title: 'Как долго будут парситься товары интернет-магазина?',
								description: (
									<>
										<p>
											Время напрямую зависит от&nbsp;количества товаров этого интернет-магазина. Мы&nbsp;стараемся
											не&nbsp;сильно нагружать сайты доноров, и, в&nbsp;то&nbsp;же время, стараемся парсить максимально
											быстро. Поэтому устанавливаем частоту 1-3 секунды на&nbsp;парсинг одной страницы сайта. Таким
											образом парсится около 40&nbsp;тысяч товаров в&nbsp;сутки.
										</p>
									</>
								),
							},
							{
								title: 'Какие интернет-магазины лучше всего парсить?',
								description: (
									<>
										<p>
											На&nbsp;самом деле разница незначительная. Парсить лучше всего интернет-магазины, имеющие
											полноценную и&nbsp;максимально объемную информацию о&nbsp;товарах, а&nbsp;также качественные
											изображения без водяных знаков. Немаловажным фактором является идентичная структура разделов
											каталога сайта. Это следствие качественного интернет-магазина с&nbsp;хорошо продуманным
											интерфейсом.
										</p>
									</>
								),
							},
							{
								title: 'Можно&nbsp;ли парсить сразу несколько интернет-магазинов?',
								description: (
									<>
										<p>
											Конечно. Мы&nbsp;можем парсить данные сразу с&nbsp;разных интернет-магазинов и&nbsp;сформировать,
											по&nbsp;возможности, единую структуру итоговых данных в&nbsp;Excel файл для удобного
											импортирования в&nbsp;ваш интернет-магазин.
										</p>
									</>
								),
							},
							{
								title: 'Выгодно&nbsp;ли парсить товары?',
								description: (
									<>
										<p>
											Конечно, и&nbsp;вот простое этому объяснение. Представьте, что каталог вашего интернет-магазина
											имеет 100 товаров и&nbsp;ежедневно вам нужно проверять их&nbsp;актуальность, сравнивать цены
											с&nbsp;конкурентами и&nbsp;предлагать товары по&nbsp;более выгодным ценам. Это необходимо делать
											регулярно и&nbsp;своевременно обновлять информацию на&nbsp;своем сайте. Это очень большой труд
											и&nbsp;скучная рутинная работа. Стоимость парсера, написанного один раз, окупается
											по&nbsp;человеко-часам уже в&nbsp;первый месяц такой работы. Это особенно хорошо заметно для
											интернет-магазинов, имеющих несколько тысяч товаров.
										</p>
									</>
								),
							},
							{
								title: 'Можно&nbsp;ли спарсить выборочную категорию интернет-магазина?',
								description: (
									<>
										<p>
											Конечно. Зачастую донорами являются интернет-магазины с&nbsp;десятками тысяч товаров разных
											категорий, но&nbsp;фактически нужны товары только одной или нескольких из&nbsp;них. Есть смысл
											настроить парсинг выборочных категорий сайта для получения только необходимой информации
											и&nbsp;не&nbsp;тратить время на&nbsp;парсинг лишнего. Таким образом данные будут обновляться чаще
											и&nbsp;вы&nbsp;будете получать более актуальную информацию.
										</p>
									</>
								),
							},
							{
								title: 'Как вы&nbsp;парсите файлы и&nbsp;изображения товаров?',
								description: (
									<>
										<p>
											Мы&nbsp;парсим URL-адреса на&nbsp;файлы и&nbsp;изображения и&nbsp;аккуратно кладем
											их&nbsp;в&nbsp;итоговый Excel файл. В&nbsp;своем интернет-магазине вы&nbsp;сможете использовать
											их&nbsp;для визуализации изображений без фактической загрузки на&nbsp;свой сервер. Также можете
											автоматически загрузить их&nbsp;на&nbsp;сайт при импорте. Современные CMS и&nbsp;расширения сайтов
											позволяют это сделать без труда.
										</p>
									</>
								),
							},
							{
								title: 'Можно&nbsp;ли изменить данные товаров при парсинге?',
								description: (
									<>
										<p>
											Да, мы&nbsp;можем менять данные &laquo;на&nbsp;лету&raquo;. Например, если вам нужно, чтобы
											клиенты при выборе между вами и&nbsp;вашим конкурентом выбрали именно ваш сайт, то&nbsp;можно
											снизить цены на&nbsp;все товары и&nbsp;установить самую низкую, выгодную цену. Также можно
											повысить уникальность описаний товаров с&nbsp;помощью синонимов. Это полезно для продвижения
											вашего интернет-магазина в&nbsp;интернете.
										</p>
									</>
								),
							},
							{
								title: 'Что нужно для заказа парсинга интернет-магазина?',
								description: (
									<>
										<p>
											Чтобы заказать парсинг интернет-магазина, отправьте заявку через форму обратной связи. Укажите
											в&nbsp;сообщении какой интернет-магазин вы&nbsp;хотите спарсить и&nbsp;подробно опишите, какие
											данные вы&nbsp;хотите получить с&nbsp;этого сайта.
										</p>
										<p>Мы&nbsp;рассчитаем цену парсинга и&nbsp;ответим вам в&nbsp;ближайшее время.</p>
									</>
								),
							},
						]}
					/>
				</div>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Задать вопрос
					</FeedbackButton>
				</div>
			</Sector>
			<Contacts color="gray" />
		</Layout>
	)
}

export default ParsingShop
